import {
  Logo,
  Navbar,
  StyledButton,
  StyledLogo,
  StyledDropdown,
} from '@monbanquet/crumble'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors, homeLayout } from '../theme'
import Link from './Link'
import PhoneLink, { StyledPhoneLink } from './PhoneLink'
import { globalHistory } from '@reach/router'
import { track } from '../analytics'
import ProfileDropdown from './ProfileDropdown'

const SiteNavbar = ({ children, childrenOnRight = false, ...props }) => {
  const envTag =
    process.env.GATSBY_ENV_NAME !== 'prod' ||
    process.env.GATSBY_ENV_DISPLAY_NAME !== 'prod' ? (
      <span
        className="envtag"
        style={{ backgroundColor: colors.env[process.env.GATSBY_ENV_NAME] }}
      >
        {process.env.GATSBY_ENV_DISPLAY_NAME}
      </span>
    ) : null

  const logo = (
    <Link to="/">
      <Logo />
      {envTag}
    </Link>
  )
  // let miniLogo = (
  //   <Link to="/">
  //     <Logo mini color={colors.env[process.env.GATSBY_ENV_NAME]} />
  //   </Link>
  // )

  return (
    <StyledSiteNavbar logo={logo} miniLogo={logo} {...props}>
      {children}
      <PhoneLink
        className="phone"
        style={{
          marginLeft: childrenOnRight ? '20px' : 'auto',
        }}
        onClick={() =>
          track('ClickPhone', {
            label: `From_Navbar`,
            category: `Page_${globalHistory.location.pathname}`,
          })
        }
      />
    </StyledSiteNavbar>
  )
}

const StyledSiteNavbar = styled(Navbar)`
  z-index: 7;
  background: transparent;

  .wrapper {
    max-width: unset;
    width: ${homeLayout.width};
  }
  .links {
    justify-content: flex-end;
    padding-left: 5px;

    > * {
      margin: 0 20px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .envtag {
    line-height: 20px;
    height: 20px;
    padding: 0 5px;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(126, 126, 126, 0.11);
    font-size: 0.9rem;
    margin-left: 10px;
    color: #fff;
  }

  .phone-desktop {
    color: ${colors.brand.black};
  }

  ${StyledLogo} {
    height: 50px;
    vertical-align: middle;
  }

  ${StyledButton} {
    height: 36px;
    &::after {
      display: none;
    }
  }

  ${StyledDropdown} {
    &::after {
      content: none !important;
    }
  }

  .phone-tablet {
    display: none;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .logo-container {
      display: flex;

      ${StyledPhoneLink} {
        margin-left: 20px;
      }
    }

    .links {
      > * {
        margin: 0 5px;
      }
    }

    .phone-tablet {
      display: block;
    }

    .phone-desktop {
      display: none;
    }

    padding-top: 0;

    ${StyledLogo} {
      height: 36px;
      vertical-align: middle;
    }
  }

  /* Iphone 4-5 */
  @media (max-width: 320px) {
    ${StyledLogo} {
      height: 22px;
    }
    .links {
      padding-left: 2px;
      > * {
        margin: 0 2px;
      }
    }
    ${StyledDropdown} button {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`

export default SiteNavbar
export { StyledSiteNavbar as StyledNavbar }
