import React, { useContext } from 'react'
import styled from 'styled-components'
import { Dropdown } from '@monbanquet/crumble'
import { AuthenticationContext } from './context/AuthenticationContext'
import { CustomerContext } from './context/CustomerContext'
import { navigate } from './Link'
import { breakpoints, colors } from '../theme'
import { LOGIN } from '../util/links'
import ProfileImg from '../assets/icons/connect.svg'

const ProfileDropdown = ({ ...props }) => {
  const { logout } = useContext(AuthenticationContext)
  const { customer } = useContext(CustomerContext)

  let buttonLabel = null
  let profileItems = []
  let btnClick

  if (typeof customer === 'undefined') {
    buttonLabel = (
      <>
        <span className="in-login">Connexion...</span>
        <span className="profile-icon">
          <img src={ProfileImg} alt="profile" />
        </span>
      </>
    )
    btnClick = () => {}
  } else if (customer === null) {
    buttonLabel = (
      <>
        <span className="login">Se connecter</span>
        <span className="profile-icon">
          <img src={ProfileImg} alt="profile" />
        </span>
      </>
    )

    btnClick = () => navigate(`/${LOGIN}`)
  } else {
    profileItems = [
      {
        label: 'Mes événements',
        value: () =>
          navigate(`${process.env.GATSBY_VENUS_FRONT_URL}/evenements`, {
            newTab: true,
          }),
      },
      {
        label: 'Mes factures',
        value: () =>
          navigate(`${process.env.GATSBY_VENUS_FRONT_URL}/factures`, {
            newTab: true,
          }),
      },
      {
        label: 'Mon compte',
        // eslint-disable-next-line no-console
        value: () =>
          navigate(`${process.env.GATSBY_VENUS_FRONT_URL}/mon-compte`, {
            newTab: true,
          }),
      },
      { label: 'Déconnexion', value: logout },
    ]
    const { firstName, company } = customer

    buttonLabel = (
      <>
        <span className="name">{firstName}</span>
        {company && <span className="company">{company}</span>}
        <span className="profile-icon">
          <img src={ProfileImg} alt="profile" />
        </span>
      </>
    )
  }

  return (
    <StyledProfileDropdown
      items={profileItems}
      onBtnClick={btnClick}
      defaultChoice={null}
      loading={typeof customer === 'undefined'}
      label={buttonLabel}
      onChange={selected => selected()}
      className={
        typeof customer === 'undefined'
          ? 'login-in'
          : customer === null
          ? 'logged-out'
          : 'logged-in'
      }
      {...props}
    />
  )
}

const StyledProfileDropdown = styled(Dropdown)`
  & > ul {
    right: 0;
  }

  &.logged-out button {
    justify-content: center;
    &::after {
      display: none;
    }
  }

  button {
    padding: 10px 30px;
    margin-right: 0;
  }

  button::after {
    height: 8px;
    margin-left: 10px;
  }

  &.logged-in button label {
    display: flex;
    flex-direction: column;
  }

  button label {
    cursor: pointer;
    .login {
      color: #000;
    }
    .in-login {
      padding-right: 20px;
      text-transform: none;
    }
  }

  .name {
    text-transform: none;
    line-height: initial;
    font-weight: bold;
  }
  .company {
    line-height: initial;
    margin-top: 3px;
    font-size: 10px;
  }

  .profile-icon {
    display: none;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    & > ul {
      left: auto;
    }

    .name {
      font-size: 10px;
    }
    button {
      height: 44px;
      width: 44px;
      border-radius: 50%;
      background-color: ${colors.brand.normal};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      .name,
      .company,
      .in-login,
      .login {
        display: none;
      }
    }

    .profile-icon {
      display: block;
    }

    button:after {
      display: none;
    }
  }
`

export default ProfileDropdown
export { StyledProfileDropdown }
