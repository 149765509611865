import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import {
  CheckoutContext,
  sendDemand,
  DEMAND_STATUS,
} from '../../components/context/CheckoutContext'
import { breakpoints, layout, colors, borders } from '../../theme'
import Navbar from '../../components/Navbar'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import {
  TextInput,
  Button,
  StyledButton,
  Pulse,
  Spinner,
} from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { buildAskForQuoteBody, computeNextPageWithBaseUri } from '../../util'
import { EventContext } from '../../components/context/EventContext'
import { PhoneContext } from '../../components/context/PhoneContext'
import { CustomerContext } from '../../components/context/CustomerContext'
import { StyledSpinner } from '@monbanquet/crumble/dist/components/Spinner/Spinner'

const schema = yup.object().shape({
  coupon: yup.string().max(50),
})

const CouponPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    state: { coupon },
    state: checkoutState,
    dispatch: dispatchCheckoutCtx,
  } = useContext(CheckoutContext)

  const {
    state: eventState,
    categories,
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const { customer } = useContext(CustomerContext)

  const noValidate = useNoValidate()
  const [error, setError] = useState()

  const submitCouponForm = async () => {
    const nextUrl = computeNextPageWithBaseUri('demande', 'coupon', {
      isUserLoggedIn: !!customer,
      customer,
    })

    if (nextUrl) {
      navigate(nextUrl, {
        state: { next: true },
      })
    } else {
      const body = buildAskForQuoteBody({
        ...checkoutState,
        ...eventState,
        categories,
      })

      const { status, response } = await sendDemand(dispatchCheckoutCtx, body)

      if (status === DEMAND_STATUS.OK) {
        navigate(
          `${process.env.GATSBY_VENUS_FRONT_URL}/evenements/${response.demandId}`,
          { newTab: false },
        )
      } else if (status === DEMAND_STATUS.NEED_MANUAL_OPERATION) {
        navigate('/demande/merci', { state: { quote: true } })
      } else if (status === DEMAND_STATUS.KO) {
        const errMsg = `Nous n'avons pas réussi à enregistrer votre demande. Veuillez nous contacter sur le chat ou par téléphone au ${e164}.`
        setError(errMsg)
        dispatchEventCtx({
          type: 'UPDATE',
          payload: { demandSource: null },
        })
      }
    }
  }

  const { formal, couponProps } = useFormalWithProps(
    {
      coupon: coupon,
    },
    {
      schema,
      onSubmit: submitCouponForm,
    },
  )

  return (
    <StyledCouponPage>
      <Navbar />
      {redirect ? null : (
        <div className="page-content">
          <SalesPictureTitle title={`Avez-vous un code promo\u00A0?`} />
          <form
            noValidate={noValidate}
            onSubmit={async e => {
              e.preventDefault()
              try {
                await formal.validate()
                await formal.submit()
              } catch (err) {
                console.info(err || formal.errors)
              }
            }}
          >
            <TextInput
              label="Code promo"
              type="coupon"
              secondary
              autoFocus
              {...couponProps}
              onChange={ce => {
                formal.change('coupon', ce)
                dispatchCheckoutCtx({
                  type: 'UPDATE',
                  payload: {
                    coupon: ce,
                  },
                })
              }}
            />
            <p className="error">{error}</p>
            <div className="btn-bar">
              <Button
                id="previous"
                disabled={formal.isSubmitting || formal.isSubmitted}
                onClick={() => navigate(-1)}
              >
                Précédent
              </Button>
              <Button
                id="next"
                disabled={formal.isSubmitting || formal.isSubmitted}
                type="submit"
                className="send-order"
              >
                {formal.isSubmitting ? (
                  <Pulse />
                ) : coupon.trim().length > 0 ? (
                  <span>Valider le code promo</span>
                ) : (
                  <span>Terminer ma demande</span>
                )}
              </Button>
            </div>
            {formal.isSubmitting && (
              <div className="done-msg">
                <span>
                  Nous créons votre événement, vous allez être redirigé
                </span>

                <Spinner />
              </div>
            )}
          </form>
        </div>
      )}
    </StyledCouponPage>
  )
}

const StyledCouponPage = styled.div`
  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
    margin: auto;
    max-width: 500px;
    width: ${layout.width};
  }
  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  form {
    margin-top: 40px;
    & > * {
      margin-bottom: 20px;
    }
    > .error {
      margin-top: 10px;
      color: ${colors.brand.dark2};
    }
  }

  .btn-bar {
    display: flex;
    margin: 30px 0;

    & > *:first-child {
      margin-right: auto;
      margin-left: 0;
      background: transparent;
      border: 1px solid ${borders.color.light};
      color: ${colors.text.light};

      &:hover {
        box-shadow: 0 2px 4px 0 rgba(126, 126, 126, 0.11);
      }
    }
    & > *:last-child {
      margin-left: 5px;
    }

    ${StyledButton} {
      height: 38px;
      border-radius: 3px;
    }
  }

  .done-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    color: ${colors.text.light};

    ${StyledSpinner} {
      margin-top: 5px;
    }
  }
`

export default CouponPage
export { StyledCouponPage }
